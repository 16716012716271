::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
 
/* Track */
::-webkit-scrollbar-track {
    -webkit-border-radius: 6px;
    border-radius: 6px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background: #f36438; 
}
::-webkit-scrollbar-thumb:window-inactive {
	background: #f36438; 
}