$number-card-border: rgba(0,0,0,.1);

.number-card-v1 {
  position: relative;
  border: 1px solid $number-card-border;
  border-radius: $border-radius-lg;
  background-color: $white;

  * {
    -webkit-backface-visibility: hidden;
    // -webkit-transform: translateZ(0) scale(1.0, 1.0);
  }

  .card-top,
  .card-bottom {
    display: flex;
    height: 7rem;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    font-size: 2.5rem;
    line-height: 1;
    font-weight: $font-weight-light;
    .h5 {
      font-size: 1.5rem;
      margin-left: 2px;
    }

    .anticon,
    .material-icons {
      font-size: 3rem;
    }
  }

  .card-bottom {
    border-top: 1px solid $number-card-border;
  }

  .card-info {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -12px;
    text-align: center;

    span {
      display: inline-block;
      height: 1.5rem;
      padding: .25rem .625rem;
      text-transform: uppercase;
      line-height: 14px;
      background-color: $white;
      border: 1px solid $number-card-border;
      font-size: .75rem;
      color: $text-muted;
      border-radius: 1em;

      .material-icons {
        line-height: .875rem;
        font-size: .6875rem; //11px
      }
    }
  }
}


// 
.number-card-v2 {
  display: flex;
  min-height: 120px;
  padding: 1rem;
  background-color: $white;
  border-radius: $border-radius-lg;
  text-align: center;
  align-items: center;
  justify-content: center;

  * {
    -webkit-backface-visibility: hidden;
  }

  p {
    margin: 0;
  }

  .icon-btn {
    margin: 0 1rem 0 0;
  }

  .box-num {
    font-size: 1.6rem;
    line-height: 1;
    margin: 0 0 .25rem;
  }
}


.number-card-v3 {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: $white;
  border-radius: $border-radius;
  overflow: hidden;

  * {
    -webkit-backface-visibility: hidden;
  }

  p {
    margin: 0;
  }

  .card-top {
    padding: 2rem 1rem;
    font-size: 2.75rem;
  }

  .card-bottom {
    padding: 1.75rem 0;

    > ul {
      display: flex;
      justify-content: space-around;
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
        position: relative;
        width: 100%;
        &:not(:last-child) {
          &:after {
            content: '';
            position: absolute;
            background-color: rgba($dark, .2);
            position: absolute;
            top: 45%;
            right: 0;
            width: 1px;
            margin-top: -15px; // height/2
            height: 45px;
          }
        }
      }
    }
  }

  .card-num {
    font-size: 1.25rem;
  }
}

.circle {
  width: 75px;
  height: 75px;
  line-height: 75px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  text-align: center;
}

.circle::after {
  content: '';
  position: absolute;
  box-sizing: border-box;
  border-radius: 50%;
  width: calc(75px + 8px);
  height: calc(75px + 8px);
  left: 5px;
  top: -4px;
}

.circle-orange {
  background: #f36438;
  background: linear-gradient(180deg, rgba(243,100,56,1) 70%, #fff 120%);
}

.circle-orange::after{
  border: 6px solid #f3643854;
}

.circle-cyan {
  background: #13c2c2;
  background: linear-gradient(180deg, #13c2c2 70%, #fff 120%);
}

.circle-cyan::after{
  border: 6px solid #13c2c254;
}

.circle-blue {
  background: #1890ff;
  background: linear-gradient(180deg, #1890ff 70%, #fff 120%);
}

.circle-blue::after{
  border: 6px solid #1890ff54;
}

.square {
  width: 75px;
  height: 75px;
  line-height: 75px;
  border-radius: 10px;
  font-size: 20px;
  color: #fff;
  text-align: center;
}

.mark {
  font-size: 11px;
  font-weight: 600;
  padding: 4px 0;
  background-color: #f36438;
  border-radius: 5px;
  color: #fff;
}

.mark-gray{
  background-color: #595959;
}

.circle-large {
  width: 175px;
  height: 175px;
  line-height: 175px;
  font-size: 30px;
}

.circle-large:after {
  width: calc(175px + 8px);
  height: calc(175px + 8px);
  left: -4px;
  top: -4px;
}